import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/http/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  fullName: string;
  letterAvatar: string;
  isAdmin: boolean = false;

  constructor(private _router: Router,
    private _translateService: TranslateService,
    private _uiLoaderService: NgxUiLoaderService,
    private _authService: AuthService,
  ) { }

  ngOnInit() {
    if (this._authService.userData) {
      this.fullName = this._authService.userData.name;
      const arr = this._authService.userData.name.split(' ');
      if (arr) {
        this.letterAvatar = arr.length > 0 ?
          arr[0].charAt(0).toUpperCase() + arr[1].charAt(0).toUpperCase()
          : arr[0].charAt(0).toUpperCase() + arr[0].charAt(1).toUpperCase();
      }
      this.isAdmin = this._authService.userData.isAdmin;
      if (!this.isAdmin) {
        this._router.navigate(['/clients']);
      }

    }
  }

  logOut() {
    this._uiLoaderService.start();
    this._authService.logOut();
    this._router.navigate(['/']);
    this._uiLoaderService.stop();
  }

  onChangeLanguage(prefix: string) {
    this._translateService.use(prefix);
    sessionStorage.lang = prefix;
  }
}
